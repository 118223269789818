$(document).ready(function () {
    $('section:in-viewport').addClass('animated');    
    initSliderPortfolio();
    scrollToElement();
    upgradeSlider();
    initPhoneMask();
    initMap();
    //    validationForm();
    submitForm();
    //    initPopup();
    $(".reelscout-slider").reelScoutSlider();
    mainSlider ();
    autoPlay();
    styler();
    checkVisibleSections();

});

$(window).on('load',function(){
    checkVisibleSections();
});
$(window).scroll(function(){
    checkVisibleSections();
});
var scrollSt = true;
function checkVisibleSections() {
    $('section:in-viewport').addClass('animated');      

    var visiblePercent = $(window).height() / 100 * 49;
    var $sections = $([]);

    $('nav a').each(function(){
        $sections = $sections.add($(this.hash));
    });
    var $menuItems = $('nav li');
    if(!$sections.filter(':in-viewport(' + visiblePercent +')').length)return;
    

    $sections.removeClass('visible');
    $menuItems.find('a').removeClass('active');
    $sections.filter(':in-viewport(' + visiblePercent +')').addClass('visible');

    var id = "#" + $sections.filter('.visible').attr('id');
    $menuItems.find('a[href="' + id + '"]').closest('a').addClass('active');
    
    
    
    if ( scrollSt  === true && $('#in-number').hasClass('visible')) {
        scrollStart();
        scrollSt = false;
    } 
}
function scrollStart(){
    
        $('#orders')
            .prop('number', 10)
            .animateNumber(
            {
                number: 1000
            },
            5000
        );
        $('#meters')
            .prop('number', 10)
            .animateNumber(
            {
                number: 2000
            },
            5000
        );
        $('#items')
            .prop('number', 10)
            .animateNumber(
            {
                number: 11000
            },
            5000
        );
    

}

function autoPlay() {
    $('.autoplay').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
    });
}
function mainSlider (){
    $('.slide-holder').innerfade({
        speed: 1500,
        timeout: 5000,
        type: 'sequence'
    });
}
function styler() {
    setTimeout(function () {
        $('input').styler({
            filePlaceholder:'Прикрепить файл'
        });
    }, 100);
}


/*function mainSlider () {
    $(".slide-holder").each(function () {

        $(this).cycle({
            slides: "> .slide-container",
            fx: "fade",


            speed: 1500,
//            timeout: 3e3,
//            paused: true
        });
    });
}*/
function initSliderPortfolio() {
    $(".gallery_big").each(function () {
        var i = $(this).siblings(".arrow_right"),
            a = $(this).siblings(".arrow_left");
        $(this).cycle({
            slides: "> .gallery_big-item",
            fx: "scrollHorz",
            pager: ".portfolio__gallery-list",
            pagerTemplate: "",
            next: i,
            prev: a,
            speed: 1500,
            timeout: 3e3,
            paused: false
        });
    });
}



function scrollToElement() {
    $('nav a,.scroll-to').click(function(e){
        var href = this.hash;

        if(!$(href).length) return;
        var distance = $(window).scrollTop() - ($(href).offset().top);
        var speed = (distance > 0) ? distance / 4 : distance * -1 / 4;

        var headerOffset = $('nav').outerHeight();


        $('html, body').animate({scrollTop: ($(href).offset().top)}, speed);
        //         $('html, body').animate({scrollTop: ($(href).offset().top - headerOffset)}, speed);
        e.preventDefault();
    });
}


function upgradeSlider() {

    var object = $('.reelscout-slider ul li'),
        objectContainer = $('.reelscout-slider ul'),
        bodyGallery = $('.reelscout-slider-body'),
        galleryDesk = $('.gallery-description');

    object.click(function () {
        setTimeout(function() {
            var activeSlide = objectContainer.find('.active img').data('image-deskription');

            galleryDesk.removeClass('active');
            bodyGallery.find('.' + activeSlide).addClass('active');
        }, 0);

    });

    var prev = $('.arrow_left1'),
        next = $('.arrow_right1'); 

    prev.click(function(event) {
        var activeMini = $('.slider-preview').find('li.active');
        var noActivePrev = activeMini.prev('li');

        noActivePrev.click();
        event.preventDefault();


    });


    next.click(function(e) {         
        var activeMini = $('.slider-preview').find('li.active');
        var noActiveNext = activeMini.next('li');


        noActiveNext.click();
        e.preventDefault();
    });





}
function initPhoneMask() {
    $('input[type="tel"]').mask("+7 (999) 999-99-99");
}

function modalSettings() {
    $('.callback').click(function(e){
        e.preventDefault();
    });
}
function initPopup() {
    $('.callback').fancybox({

        /*minHeight:500,

        tpl: {
            wrap:'<div class="fancybox-wrap" tabIndex="-1"><div class="fancybox-skin" style="background:none; box-shadow:none; padding:0;"><div class="fancybox-outer"><div class="fancybox-inner" ></div></div></div></div>',

        },*/
    });


}
function submitForm() {
    $(".wpcf7").on('wpcf7:mailsent', function(event){ 
        $.fancybox('#popup__tnx',{ 

        });                     
        $('form').trigger('reset');

        $('.modal').modal('hide');
        $('.jq-file__name').html('Прикрепить файл');
        yaCounter39763120.reachGoal("ORDER");


    });
}


function initMap() {


    if(typeof ymaps == 'undefined') return;
    $('#location__map').html('');
    var myMap, 
        myPlacemark;

    function init(){ 
        myMap = new ymaps.Map("location__map", {
            center:[55.703432,37.839799],
            zoom: 16

        }); 

        myMap.behaviors.disable('scrollZoom');

        myPlacemark = new ymaps.Placemark([55.703432,37.839799], {}, {
            //            iconLayout: 'default#image',
            //            iconImageHref: 'wp-content/themes/gabaulun/images/placmark.png',
            //            iconImageSize: [51, 57],
            //            iconImageOffset: [-11, -53]
        });

        myMap.geoObjects.add(myPlacemark);
    }
    ymaps.ready(init);

    /*if (typeof ymaps == 'undefined') return;
    $('#location__map').html('');
    var myMap,
        myPlacemark;

    function init() {
        myMap = new ymaps.Map("location__map", {
            center: [55.704458, 37.840651],
            zoom: 16
        });
        myMap.behaviors.disable('scrollZoom');
        myPlacemark = new ymaps.Placemark([55.704458, 37.840651], {}, {});
        myMap.geoObjects.add(myPlacemark);
    }
    ymaps.ready(init);*/
}